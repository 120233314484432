import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  var _component_Pagination = _resolveComponent("Pagination");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "党校培训",
    back: true
  }), _createVNode(_component_el_table, {
    data: _ctx.tableData,
    style: {
      "width": "100%"
    },
    "empty-text": "暂无数据",
    border: ""
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        prop: "organizeName",
        label: "组织名称"
      }), _ctx.title != '请假' ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 0,
        prop: "userName",
        label: "人员列表"
      })) : (_openBlock(), _createBlock(_component_el_table_column, {
        key: 1,
        prop: "proposerName",
        label: "人员列表"
      }))];
    }),
    _: 1
  }, 8, ["data"]), _createVNode(_component_Pagination, {
    pagesize: 10,
    "total-num": _ctx.totalNum,
    onChangPage: _ctx.changPage
  }, null, 8, ["total-num", "onChangPage"])], 64);
}